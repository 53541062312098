import React from 'react';
import SEO from '../components/Seo';
import HowTo from '../components/sections/HowTo';
import Quote from '../components/sections/Quote';
import OurBenefits from '../components/sections/OurBenefits';
import Value from '../components/sections/Value';
import Themes from '../components/sections/Themes';
import HeroAlt from '../components/sections/hero/HeroAlt';
import Questions from '../components/sections/Questions';

export default function HomePage() {
  return (
    <>
      <SEO title="Global Belief ETF" />
      <main className="page-home page-home--b">
        <HeroAlt title="Exchange-Traded Funds" />
        <Value />
        <OurBenefits />
        <Quote />
        <Themes />
        <HowTo />
        <Questions />
      </main>
    </>
  );
}
