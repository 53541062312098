import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import HeroText from './HeroText';

interface Props {
  title: string | React.ReactChild;
}

export default function HeroAlt({ title }: Props) {
  return (
    <section className="container section-m-b page-m-t hero hero--alt">
      <div className="row">
        <div className="col-xs-12 col-sm-9 col-md-6 col-lg-5 hero__text-col">
          <HeroText title={title} />
        </div>
        <div className="col-xs-12 col-sm-3 col-md-6 col-lg-6 col-lg-offset-1">
          <div className="hero__img">
            <div className="hero__img__inner">
              <StaticImage
                src="../../../images/hero-img.jpg"
                alt=""
                placeholder="blurred"
                loading="eager"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
